import {Category, Product, Publisher} from '@/api/generated';
import {GoogleAnalyticsItem} from '@/typings/google-analytics';
import {Ref} from 'vue';

type CategoryKeys = {
	item_category?: string;
} & {
	[K in `item_category${2 | 3 | 4 | 5}`]?: string;
};

export default function productToGoogleEcommerceItem (product: Ref<Product>, index: number): GoogleAnalyticsItem {
	const categories: CategoryKeys = {};
	let lastIndex = 1;
	if (product.value.category) {
		Object.values(product.value.category?.parentCategories ?? {})?.forEach((cat: Category, i) => {
			if (lastIndex == 1) {
				categories.item_category = cat.name ?? '';
				lastIndex++;
				return;
			}
			categories[`item_category${lastIndex.toFixed().toString()}` as keyof CategoryKeys] = cat.suggestedName ?? cat.name;
			lastIndex++;
		})
		categories[`item_category${(lastIndex).toFixed().toString()}` as keyof CategoryKeys] = product.value.category?.suggestedName ?? product.value.category?.name ?? '';
	}

	const publishers: {item_brand?: string} = {};
	if (product.value.publishers) {
		publishers.item_brand = Object.values(product.value.publishers).map((publisher: Publisher) => publisher.name)[0] ?? ''
	}

	return {
		item_name: product.value.name,
		item_id: product.value.productId.toFixed(0).toString(),
		index: index,
		price: product.value.price ?? 0,
		...publishers,
		...categories,
	};
}
