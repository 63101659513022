import {Product} from '@/api/generated';
import {GoogleTagEvent, GoogleTagEventNames} from '@/typings/google-tag-manager';
import productToGoogleEcommerceItem from '@/ui/utils/dataLayer/productToGoogleEcommerceItem';
import {Ref} from 'vue';
import {GoogleAnalyticsItem} from '@/typings/google-analytics';

const defaultParams = {
	currency: 'PLN',
};

export default function item() {
	function cleanUpEcommerce (): void {
		window.dataLayer.push({
			ecommerce: null,
		});
	}

	function viewItem(product: Product): void {
		cleanUpEcommerce();
		window.pushDataLayer(
			{
				event: 'view_item' as GoogleTagEventNames,
				ecommerce: {

				},
			} as GoogleTagEvent,
		);
	}

	function selectItem(item: GoogleAnalyticsItem, itemListName: string = 'LP - swiper produktowy'): void {
		cleanUpEcommerce();
		window.pushDataLayer(
			{
				event: 'select_item' as GoogleTagEventNames,
				ecommerce: {
					item_list_name: itemListName,
					items: [
						item,
					]
				},
			} as GoogleTagEvent,
		);
	}

	return {
		viewItem,
		selectItem,
	};
}
